import React from "react"

import Template from "../../components/Template"
import SEO from "../../components/seo"
import style from "./style.module.scss"

const Episode2 = () => (
  <Template>
    <SEO title="2. Lalande" />
    <div className={style.episode}>
      <div className={style.heroImage}>
        <div className={style.title}>
          <div className={style.titleContent}>
            <h2>Episode 2.</h2>
            <h1>Lalande</h1>
          </div>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.copy}>
          <p>
            Lalande ran his knuckles through a spatter of blood on the floor,
            bringing it to his nostrils. She was wounded, the iron-rich scent
            seeming to heighten his senses. The trail of red drops drew an
            erratic pattern on the ground, mapping out her fear as plainly as if
            were spelled in words.
          </p>
          <p>
            It was easy to follow, and he knew the base as well as his own
            spots. When the ground started to slope upwards, he had a good idea
            of where she was headed.
          </p>
          <p>
            Natans' concrete walls contained a maze of underground tunnels and
            shaded courtyards designed to repel the desert heat, but the smell
            of fresh outside air emanating from the end of the hall must have
            been irresistible. Unfortunately for her, the inner courtyard was
            also full of soldiers practicing drills this time of day and the
            echo of their surprised shouts lead him right to her.
          </p>
          <p>
            She must have thought the outside air meant freedom, but Lalande
            knew better, not even bothering to increase pace as he pursued her.
            He walked, the scent of blood and fear growing stronger on the still
            desert air. Soon he was trailing her by only a few yards, the
            unevenness of her stride now obvious. She was limping, leaning
            against the concrete walls every few steps for steadiness.
          </p>
          <p>"You're only making this harder on yourself," he said coldly.</p>
          <p>
            The courtyard was ending. They could both see the walls join in a
            narrow point, too high and sheer to scale - not that it stopped her
            from trying. She desperately scratched at the concrete with her
            handpaws, only stopping to whirl around and face him when he got too
            close, teeth bared, ears pinned, and snarling like a wild animal.
          </p>
          <p>
            It was a fearsome display, but he had her backed into a corner, and
            this close, Lalande could clearly see the severity of the wound on
            her outer thigh, matting the fur on her leg down to her footpaw. It
            looked like a puncture, a deep one.
          </p>
          <p>So, that was why she'd been limping.</p>
          <p>
            He stood just out of reach watching her chest rise and fall
            unevenly. She wore no clothes, but that wasn't so unusual. The
            Animalkind that worked the mines and the refinery at Natans were
            regarded as little more than beasts, kept in filthy pens and valued
            only for their ability to survive the harsh conditions of the
            near-airless underground mineshafts.
          </p>
          <p>A fate he himself had only narrowly avoided.</p>
          <p>
            He reasoned that with her limited exposure of the outside world, it
            really was more like dealing with an animal than one of the
            intelligent members of his own kind, and was considering how to most
            easily subdue her, when her snarling abated to something like words.
            A difficult-to-understand dialect of Pantaren, the language of
            Animalkind, touched his ears.
          </p>
          <p>"What.. is... this place?" her breathing was ragged.</p>
          <p>
            A muscle near Lalande's eye twitched in the mildest gesture of
            surprise. He hadn't expected her to be able to speak at all, but he
            responded with a low growl. "Natans."
          </p>
          <p>
            Her whole face formed into a strained expression, like she was
            trying to understand him from very far away. At the same moment, her
            uninjured leg began to tremble noticeably, and he thought he saw her
            sink an inch or two down the wall. Some of the soldiers had caught
            up with them by now, forming a half-circle at his rear that made his
            ears twitch at individual voices.
          </p>
          <p>"That's the one the patrol found," someone said.</p>
          <p>"You think it escaped?"</p>
          <p>"I heard it was smuggled out."</p>
          <p>
            Lalande had heard, too. He'd been there when the patrol commander
            came furiously banging on Charlevere's door. He'd heard their
            arguments, watched her pace the library for hours rehashing
            conversations after everyone else had gone to sleep. While many
            possible scenarios had been explained on how this unremarkable
            creature was found outside the walls of the base, he knew that
            Charlevere would accept none of them.
          </p>
          <p>
            All scenarios made her look bad, and she would not stop searching
            for an outcome in which she wasn't culpable.
          </p>
          <p>
            Lalande was admittedly quite curious what had happened, too. Due to
            it's remote location, the base was considered inescapable; most did
            not even know of it's existence, and even under threat of torture,
            the man the patrol had apprehended never changed his story.
          </p>
          <p>
            The puncture wound certainly fit the mans earlier description of an
            arrow, but it still didn't mean he was telling the truth. If there
            was anything Lalande knew about humankind, it was that they could be
            very convincing liars.
          </p>
          <p>
            Her green eyes searched the crowd wildly, but Lalande noticed her
            breathing had become considerably slower. When she slid down the
            wall, laughter and cheering erupted behind him, but he ignored the
            noise to take a few steps closer.
          </p>
          <p>
            She was on the ground, slouched against the wall with her mouth
            hanging slightly open, panting. Up close, he could see that her fur
            was much thicker than his, colored like streaked gray stone. It was
            hard to tell where the dirt stopped and her true markings started,
            but easy enough to see that it was not just the blood loss that was
            taking a toll - it was the heat.
          </p>
          <p>
            A high pitched voice called his name and he straightened as the
            overseer appeared through the crowd.
          </p>
          <p>
            "Nicely done, Lae," she said, squeezing his arm before stepping past
            him to take a closer look. "Looks a bit like you, doesn't it?"
          </p>
          <p>
            He didn't respond. Although the overseer knew he could, having
            taught him how to speak herself, most of their conversations were
            very one sided, so his silence wasn't unusual. Speech from the
            Animalkind disturbed most humans, no matter what they said to the
            contrary, so he only responded when necessary to avoid developing
            the habit.
          </p>
          <p>
            When the creature growled, Charlevere stepped back and Lalande
            stepped protectively forward.
          </p>
          <p>
            "Goodness, she's wilder than a sandstorm," said the overseer from
            behind him. "My question is, how did anyone get close enough to get
            her out of the mines in the first place?"
          </p>
          <p>
            Lalande crouched down to examine the trail of blood running down one
            leg, the chafed fur around her wrists and footpaws telling the story
            easily enough. She'd been restrained, injured, possibly starved if
            the protrusion of her hips was any indication. Whatever fight she
            had left was being quickly sapped by the heat, and the sight of her
            half-closed eyes made him feel an unexpected twinge of pity.
          </p>
          <p>
            He didn't normally feel this way about carrying out the overseers
            orders, but then, he wasn't usually hunting down his own kind.
          </p>
          <p>
            "Well, I guess you can put her in the aviary until Krast is back
            from surveying," said Charlevere.
          </p>
          <p>
            It was as if the overseers words were coming from very far away. At
            the sight of the stunted, miserable-looking creature, he suddenly
            realized he'd never actually encountered one of the Animalkind from
            the mines before. Only heard the stories. The reality came as a
            shock, his pupils going narrow as coins.
          </p>
          <p>"Lalande."</p>
          <p>
            He blinked as the sound of the overseers voice dropped an octave,
            quickly shifting into motion. Taking care to avoid her injured leg,
            he hooked his forearm under her knees and easily scooped the small
            creature up into both arms, her head lolling listlessly against his
            chest. Charlevere bent forward to get a better look at her features
            until her claws extended unconsciously, making the overseer step
            away with a wrinkle of her nose.
          </p>
          <p>
            It felt surreal to look upon the face of his own kind, triggering
            memories he thought he'd long forgotten. He looked straight ahead as
            the faces of other Animalkind flashed in his mind, his eyes focusing
            on the back of the overseers neck.
          </p>
          <p>
            He'd long ago taught himself to ignore feelings and thoughts that
            made him lose focus like this, but they now felt like an onslaught.
            It was lucky for the loud squawking of birds as they approached the
            aviary, or he might not have noticed where he was walking at all.
          </p>
          <p>
            Charlevere opened the gate to the aviary herself, producing a key
            from a belt on her waist. It was a huge metal cage, easily the size
            of a horses stall with a grille of fine metal bars designed for
            containing birds.
          </p>
          <p>
            Thirty or forty beautifully plumed birds flew to the opposite side
            the moment they saw the Animalkind among them, chirping frantically,
            but the creature in Lalande's arms hardly stirred when he placed her
            on the floor. He lingered a moment before retreating, watching her
            breathe. Then the overseers hand was on his elbow again.
          </p>
          <p>
            "Well done Lae," she said, closing the gate and turning the key in
            the lock twice. "That wasn't so difficult at all, was it? And to
            think it took fifteen men to chase her down."
          </p>
          <p>
            Lalande crossed his arms by way of response, not looking at the
            overseer, but past her, through the narrow slits of the aviary bars.
          </p>
          <p>"Lalande."</p>
          <p>
            He blinked, Charlevere's face suddenly back in focus. She had a grin
            playing at her features, but Lalande could sense something else in
            her tone. Curiosity. She'd noticed him staring, but seemed satisfied
            not to press the matter now that she had his attention again.
          </p>
          <p>"Come along, love."</p>
        </div>
      </div>
      <div className={style.fullWidthImage}>fullWidthImage</div>
    </div>
  </Template>
)

export default Episode2
